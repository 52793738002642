import { filter } from 'lodash'

export const eventStatusConfig = {
  open: { label: '進行中', value: 'open', tagType: 'action' },
  finished: { label: '已結束', value: 'finished', tagType: 'info' },
  notYet: { label: '未開始', value: 'notYet', tagType: 'info' },
}

export const eventTypeConfig = {
  register: { label: '註冊禮', value: 'register' },
  level: { label: '升等禮', value: 'level' },
  birthday: { label: '生日禮', value: 'birthday' },
  appointmentOrderCompleted: {
    label: '預約消費禮',
    value: 'appointmentOrderCompleted',
  },
  ecOrderCompleted: { label: '電商消費禮', value: 'ecOrderCompleted' },
  saleRecordCompleted: { label: '銷售消費禮', value: 'saleRecordCompleted' },
  memberStoreOrderCompleted: {
    label: '會員商城消費禮',
    value: 'memberStoreOrderCompleted',
  },
  memberExternalTransactionCompleted: {
    label: '第三方銷售消費禮',
    value: 'memberExternalTransactionCompleted',
  },
  memberReferralRecordSuccessReferrer: {
    label: '推薦禮-獎勵舊會員',
    value: 'memberReferralRecordSuccessReferrer',
  },
  memberReferralRecordSuccessReferee: {
    label: '推薦禮-獎勵新會員',
    value: 'memberReferralRecordSuccessReferee',
  },
  multiSource: {
    label: '混合消費禮',
    value: 'multiSource',
  },
  subscriptionPlanBenefits: {
    label: '訂閱方案權益發放',
    value: 'subscriptionPlanBenefits',
  },
}

//  事件設定 ->  事件範本
export const eventTypeConsumption = [
  {
    label: '首次完成單筆訂單滿額',
    value: 'firstOrderAmount',
    configSpec: {
      title: '超出指定金額',
      unit: '元',
    },
  },
  {
    label: '首次完成單筆訂單滿件數',
    value: 'firstOrderNumber',
    configSpec: {
      title: '超出指定件數',
      unit: '件',
    },
  },
  {
    label: '完成單筆訂單滿額',
    value: 'orderAmount',
    configSpec: {
      title: '超出指定金額',
      unit: '元',
    },
    configRange: {
      title: '單筆滿足消費金額區間',
      unit: '元',
    },
  },
  {
    label: '完成單筆訂單滿件數',
    value: 'orderNumber',
    configSpec: {
      title: '超出指定件數',
      unit: '件',
    },
    configRange: {
      title: '單筆滿足消費件數區間',
      unit: '件',
    },
  },
  {
    label: '完成累積訂單滿額',
    value: 'orderSumAmount',
    configSpec: {
      title: '訂單累積金額',
      unit: '元',
    },
  },
  {
    label: '完成累積訂單滿件數',
    value: 'orderSumNumber',
    configSpec: {
      title: '訂單累積滿件',
      unit: '件',
    },
  },
  {
    label: '單筆訂單完成指定商品滿額',
    value: 'orderSpecAmount',
    configSpec: {
      title: '超出指定金額',
      unit: '元',
    },
    configRange: {
      title: '單筆滿足消費金額區間',
      unit: '元',
    },
  },
  {
    label: '單筆訂單完成指定商品滿件數',
    value: 'orderSpecNumber',
    configSpec: {
      title: '超出指定商品件數',
      unit: '件',
    },
    configRange: {
      title: '單筆滿足購買指定商品件數區間',
      unit: '件',
    },
  },
  {
    label: '累積訂單完成指定商品滿額',
    value: 'orderSumSpecAmount',
    configSpec: {
      title: '指定商品購買金額',
      unit: '元',
    },
  },
  {
    label: '累積訂單完成指定商品滿件數',
    value: 'orderSumSpecNumber',
    configSpec: {
      title: '指定商品購買件數',
      unit: '件',
    },
  },
  // {
  //   label: '完成累積訂單滿件數',
  //   value: 'orderSumNumber',
  //   configSpec: {
  //     title: '訂單累積滿件',
  //     unit: '件',
  //   },
  // },
  // {
  //   label: '累積推薦滿人數',
  //   value: 'referralPeopleSum',
  //   configSpec: {
  //     title: '推薦滿',
  //     unit: '人',
  //   },
  // },
]

//  事件設定  ->  事件類型
export const eventSourceConfig = [
  {
    label: '等級禮',
    value: 'level',
    type: [
      {
        label: '升等',
        value: 'upLevel',
      },
    ],
  },
  {
    label: '生日禮',
    value: 'birthday',
    type: [
      {
        label: '當月壽星活動',
        value: 'birthCurrentMonth',
      },
    ],
  },
  {
    label: '註冊禮',
    value: 'register',
    type: [
      // {
      //   label: '完成註冊',
      //   value: 'register',
      // },
      {
        label: '完成綁定line',
        value: 'lineAuth',
      },
    ],
  },
  {
    label: '推薦禮-獎勵舊會員',
    value: 'memberReferralRecordSuccessReferrer',
    type: [
      {
        label: ' 累積推薦滿人數',
        value: 'referralPeopleSum',
      },
    ],
  },
  {
    label: '推薦禮-獎勵新會員',
    value: 'memberReferralRecordSuccessReferee',
    type: [
      {
        label: ' 完成註冊與Line綁定',
        value: 'registerAndLineAuth',
      },
    ],
  },
  {
    label: '預約消費禮',
    value: 'appointmentOrderCompleted',
    eventSpecsType: 'appointmentService',
    type: [...eventTypeConsumption],
  },
  {
    label: '銷售消費禮',
    value: 'saleRecordCompleted',
    eventSpecsType: 'salesProduct',
    type: [...eventTypeConsumption],
  },
  {
    label: '電商消費禮',
    value: 'ecOrderCompleted',
    eventSpecsType: 'ecommerceProduct',
    type: [...eventTypeConsumption],
  },
  {
    label: '會員商城消費禮',
    value: 'memberStoreOrderCompleted',
    eventSpecsType: 'memberStoreProduct',
    type: [...eventTypeConsumption],
  },
  {
    label: '第三方銷售消費禮',
    value: 'memberExternalTransactionCompleted',
    eventSpecsType: 'memberExternalTransactionItem',
    type: [...eventTypeConsumption],
    // filter(eventTypeConsumption, (eventTemplate) => {
    //   const selectTypes = ['firstOrderAmount', 'orderAmount', 'orderSumAmount']
    //   return selectTypes.includes(eventTemplate.value)
    // }),
  },
  {
    label: '混合消費禮',
    value: 'multiSource',
    eventSpecsType: 'multiTransaction',
    type: filter(eventTypeConsumption, (eventTemplate) => {
      const selectTypes = ['orderSumAmount', 'orderSumNumber', 'orderSumSpecAmount', 'orderSumSpecNumber']
      return selectTypes.includes(eventTemplate.value)
    }),
  },
  {
    label: '訂閱方案權益發放',
    value: 'subscriptionPlanBenefits',
    type: [
      {
        label: '指定方案完成單筆扣款紀錄付款',
        value: 'singlePaymentRecord',
      },
      {
        label: '指定方案依照權益週期發放權益',
        value: 'periodicBenefit',
      },
    ],
  },
]

export const eventSourceContainsConfig = {
  appointmentOrderCompleted: {
    label: '預約訂單',
    value: 'appointmentOrderCompleted',
  },
  saleRecordCompleted: {
    label: '銷售紀錄訂單',
    value: 'saleRecordCompleted',
  },
  ecOrderCompleted: {
    label: '電商訂單',
    value: 'ecOrderCompleted',
  },
  memberStoreOrderCompleted: {
    label: '會員商城訂單',
    value: 'memberStoreOrderCompleted',
  },
  memberExternalTransactionCompleted: {
    label: '第三方銷售訂單',
    value: 'memberExternalTransactionCompleted',
  },
}

export const eventSoruceMappingSpecType = {
  appointmentOrderCompleted: 'appointmentService',
  saleRecordCompleted: 'salesProduct',
  ecOrderCompleted: 'ecommerceProduct',
  memberStoreOrderCompleted: 'memberStoreProduct',
  memberExternalTransactionCompleted: 'memberExternalTransactionItem',
}

//  事件進階設定
export const eventAdvancedConfig = [
  {
    label: '當月壽星',
    value: 'isNowBirthday',
  },
  {
    label: '完成訂單前30日註冊會員者',
    value: 'is30daysNewMember',
  },
  {
    label: '銀卡',
    value: 'level_1',
  },
  {
    label: '金卡',
    value: 'level_2',
  },
  {
    label: '鑽石藍卡',
    value: 'level_3',
  },
  {
    label: '曜石黑卡',
    value: 'level_4',
  },
  {
    label: '預約歷史紀錄者',
    value: 'haveAppointmentHistory',
  },
  {
    label: '銷售歷史紀錄者',
    value: 'haveSalesHistory',
  },
  {
    label: '電商歷史紀錄者',
    value: 'haveEcommerceHistory',
  },
  {
    label: '會員商城歷史紀錄者',
    value: 'haveMemberStoreHistory',
  },
]

//  事件總活動領獎次數規則
export const eventNumberOfReceiptsConfig = [
  {
    label: '單一會員可重複領取',
    value: 'repeat',
  },
  {
    label: '單一會員只能領取1次',
    value: 'once',
  },
]

// 單筆消費規則
export const singleConsumptionTypeConfig = [
  {
    label: '單筆符合消費條件只計算一次',
    value: 'once',
  },
  {
    label: '單筆符合消費條件可重複計算',
    value: 'repeat',
  },
]

// 總活動領獎次數進階設定
export const rewardCountAdvanceOptionsConfig = [
  { label: '無', value: 'none' },
  { label: '單一會員獎勵數量上限', value: 'maxMemberAwardCount' },
]

// 單筆訂單領獎數量設定
export const singleOrderAwardCountConfig = [
  { label: '無', value: 'none' },
  { label: '單筆訂單獎勵數量上限', value: 'maxSingleAwardCount' },
]

//  事件行銷 累積計算方式選項
export const eventSumUpConfigConfig = [
  {
    label: '每次累積',
    value: 'rate',
  },
  {
    label: '總累積',
    value: 'once',
  },
]

export const eventHistoryConfig = [
  {
    label: '不回朔',
    value: 0,
  },
  {
    label: '追加過去歷史180天',
    value: 180,
  },
  {
    label: '追加過去歷史360天',
    value: 360,
  },
]

//  事件行銷項目
export const eventAwardConfig = [
  {
    label: '堂票',
    value: 'classTicket',
  },
  {
    label: '票券',
    value: 'coupon',
  },
  {
    label: '點數',
    value: 'point',
  },
  {
    label: '回饋金',
    value: 'cashback',
  },
  {
    label: '圖文訊息',
    value: 'lineMessage',
  },
]

//  進階設定 基於進階設定config 移除選項
export const registerFilters = eventAdvancedConfig
  .filter((item) => item.value !== 'isNowBirthday')
  .map(({ value }) => value)

export const levelFilters = [
  'isNowBirthday',
  'is30daysNewMember',
  'level_1',
  'haveAppointmentHistory',
  'haveSalesHistory',
  'haveEcommerceHistory',
  'haveMemberStoreHistory',
]
export const birthdayFilters = ['isNowBirthday', 'is30daysNewMember']
export const consumptionFilters = [
  'isNowBirthday',
  'haveAppointmentHistory',
  'haveSalesHistory',
  'haveEcommerceHistory',
  'haveMemberStoreHistory',
]

const consumptionWord = {
  repeat: '規則說明：同一位會員在活動期間內達到門檻後，可持續參與活動',
  once: '規則說明：同一位會員在活動期間內只能參與一次活動',
}

export const eventTipsConfig = {
  register: {
    repeat: '規則說明：同一組電話號碼在活動期間內解綁，改綁定其他會員可重複領取。',
    once: '規則說明：同一會員在活動期間內解綁與變更電話號碼後，無法再領取',
  },
  level: {
    repeat: '規則說明：同一位會員在期間內被降級又升級，達到同條件仍可領取',
    once: '規則說明：同一位會員在活動期間內只能領取一次此卡別的升等禮',
  },
  birthday: {
    repeat: '規則說明：活動期間內編輯生日，如過去領過但下個月判斷符合條件仍可領取。',
    once: '規則說明：活動期間內同一位會員只能拿一次壽星禮物。',
  },
  appointmentOrderCompleted: { ...consumptionWord },
  ecOrderCompleted: { ...consumptionWord },
  saleRecordCompleted: { ...consumptionWord },
  memberStoreOrderCompleted: { ...consumptionWord },
  memberExternalTransactionCompleted: { ...consumptionWord },
  memberReferralRecordSuccessReferee: { ...consumptionWord },
  subscriptionPlanBenefits: { ...consumptionWord },
}

export const singleConsumptionWord = {
  once: '規則說明：當單筆消費超出指定數量，僅會發送一次行銷項目。例如消費 2000 元送 10 點，當客戶消費超出 2000 元，仍只會贈送消費者 10 點。',
  repeat: '規則說明：當單筆消費超出指定數量，且達該數量之倍數，將累積發送倍數的行銷項目。若指定數量未滿倍數，則無條件捨去。例如消費 2000 元送 10 點，當客戶消費 4000 元會贈送消費者 20 點；而消費 3999 元則贈送 10 點。',

}

export const sendStatusConfig = {
  success: { label: '發送成功', value: 'success', tagType: 'action' },
  pending: { label: '等待處理', value: 'pending', tagType: 'info' },
  failed: { label: '發送失敗', value: 'failed', tagType: 'danger' },
}

export const orderByOptionsConfig = {
  sendAtAsc: { label: '發送日期 近 -> 遠', value: 'ASC' },
  sendAtDesc: { label: '發送日期 遠 -> 近', value: 'DESC' },
}

export const textSettingConfig = [
  'firstOrderAmount',
  'firstOrderNumber',
  'orderAmount',
  'orderNumber',
  'orderSumAmount',
  'orderSumNumber',
  'orderSpecAmount',
  'orderSpecNumber',
  'orderSumSpecAmount',
  'orderSumSpecNumber',
]

// 消費滿額設置範本
export const amountSettingConfig = [
  'firstOrderAmount',
  'firstOrderNumber',
  'orderAmount',
  'orderNumber',
  'orderSpecAmount',
  'orderSpecNumber',
]
// 需選擇指定商品範本
export const productConfig = [
  'orderSpecAmount',
  'orderSpecNumber',
  'orderSumSpecAmount',
  'orderSumSpecNumber',
]

// 累積計算方式範本
export const accumulationConfig = [
  'orderSumAmount',
  'orderSumNumber',
  'orderSumSpecAmount',
  'orderSumSpecNumber',
]

// 單筆消費規則範本
export const singleConsumptionConfig = [
  'orderAmount',
  'orderNumber',
  'orderSpecAmount',
  'orderSpecNumber',
]
